.track-gradient::-webkit-slider-runnable-track {
	background-image: linear-gradient(
		to right,
		theme('colors.julaRed') 0%,
		theme('colors.julaRed') var(--progress),
		theme('colors.greyLight') var(--progress),
		theme('colors.greyLight') 100%
	);
}
@media (forced-colors: active) {
	.track-gradient::-webkit-slider-runnable-track {
		background-image: linear-gradient(
			to right,
			CanvasText 0%,
			CanvasText var(--progress),
			Field var(--progress),
			Field 100%
		);
	}
}
